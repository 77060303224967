window.inputTemplate = function (input) {
  const id = Math.random().toString().substr(2, 5)

  typeof input.required === 'undefined' ? input.required = true : ''
  typeof input.class === 'undefined' ? input.class = '' : ''
  typeof input.value === 'undefined' ? input.value = '' : ''

  if (input.type === 'checkbox') {
    return `<div class="form-row"><div class="custom-control custom-checkbox">
  <input type="checkbox" class="custom-control-input" id="${input.name}${id}" name="${input.name}" value="1">
  <label class="custom-control-label" for="${input.name}${id}">${input.text}</label>
</div></div>`
  }

  let html = `<div class="form-row"><label for="${input.name}">${input.text} ${input.required ? '<span class="required">Requerido</span>' : ''}</label>`

  if (input.type === 'hidden') {
    html = '<div class="form-group">'
  }

  if (input.name === 'patient_id' && input.type === 'select') {
    html += `<div class="col-md-10 col-9 px-0"><select name="${input.name}" id="${input.name}${id}" class="form-control ${input.class}" ${input.required ? 'required' : ''}></select></div>
                <div class="col-md-2 col-3"><button type="button" class="btn btn-primary"  data-toggle="modal" data-target="#newPatientBtnModal" disabled id="newPatientBtn">Nuevo</button></div>`
  } else {
    if (input.type === 'select') {
      html += `<select name="${input.name}${input.multiple ? '[]' : ''}" id="${input.name}${id}" class="form-control ${input.class}" ${input.required ? 'required' : ''} ${input.multiple ? 'multiple' : ''}></select>`
    } else if (input.type === 'textarea') {
      html += `<textarea name="${input.name}" id="${input.name}${id}" class="form-control ${input.class}" ${input.required ? 'required' : ''}></textarea>`
    } else if (input.type === 'file' && input.multiple) {
      html += `<input type="file" id="${input.name}${id}" name="${input.name}[]" multiple>`
    } else if (input.type === 'file') {
      html += `<input type="file" id="${input.name}${id}" name="${input.name}">`
    } else {
      if (input.input === 'currency') {
        html += `<div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon${id}">$</span>
                        </div>
                        <input type="text" inputmode="numeric" name="${input.name}"
                         id="${input.name}-${id}" aria-describedby="${input.name}Help"
                         class="currency-input form-control ${input.class}" ${input.required ? 'required' : ''}
                         value="${input.value ? input.value : ''}" min="${input.min}" max="${input.max}">
                    </div>`
      } else if (input.input === 'decimal') {
        html += `<input type="${input.type}" name="${input.name}" id="${input.text}-${id}" class="form-control ${input.class}" ${input.required ? 'required' : ''} value="${input.value ? input.value : ''}"
min="${input.min}" max="${input.max}" step=".01">`
      } else {
        html += `<input type="${input.type}" name="${input.name}" id="${input.text}-${id}" class="form-control ${input.class}" ${input.required ? 'required' : ''} value="${input.value ? input.value : ''}"
min="${input.min}" max="${input.max}">`
      }
    }
  }

  html += '</div>'

  return html
}
