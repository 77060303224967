require('./bootstrap')
require( 'datatables.net-bs4' )
require( 'datatables.net-responsive-bs4' )
require( 'datatables.net-buttons-bs4' )
require( 'datatables.net-buttons/js/buttons.colVis.js' )
require( 'datatables.net-buttons/js/buttons.flash.js' )
require( 'datatables.net-buttons/js/buttons.html5.js' )
require( 'datatables.net-buttons/js/buttons.print.js' )
require( 'datatables.net-autofill-bs4' )
require( 'datatables.net-colreorder-bs4' )
require( 'datatables.net-fixedcolumns-bs4' )
require( 'datatables.net-fixedheader-bs4' )
require( 'datatables.net-rowreorder-bs4' )
require( 'datatables.net-scroller-bs4' )
require( 'datatables.net-select-bs4' )
// bs4 no js - require direct component
// styling only packages for bs4
require( 'datatables.net-keytable' )
require( 'datatables.net-rowgroup' )
window.Dropzone = require('dropzone')
require('startbootstrap-sb-admin-2/js/sb-admin-2.min')
require('datatables.net-select-bs4/js/select.bootstrap4.min')
require('./custom')
require('./templates/modalTemplate')
require('./templates/inputTemplate')
require('./templates/actionsDropdownTemplate')
window.SlimSelect = require('slim-select')
require('@fancyapps/fancybox')
window.SimpleMaskMoney = require('simple-mask-money')
window.numeral = require('numeral')
window.locales = require('numeral/locales')

numeral.register("locale", "es-ar", {
    delimiters: {
        thousands: ".",
        decimal: ","
    },
    abbreviations: {
        thousand: "k",
        million: "m",
        billion: "b",
        trillion: "t"
    },
    currency: {
        symbol: "$"
    }
})

numeral.locale('es-ar')
