window.afterPageLoad = function () {
  deleteButtons()
  removeButtons()
  const blur = document.querySelector('.blur')
  if (blur) {
    blur.classList.remove('blur')
  }

  simpleMaskMoney()
}

window.simpleMaskMoney = function () {
  document.querySelectorAll('.currency-input').forEach(currency => {
    const options = {
      prefix: '',
      suffix: '',
      fixed: true,
      fractionDigits: 2,
      decimalSeparator: ',',
      thousandsSeparator: '.',
      autoCompleteDecimal: true
    }

    currency.mask = SimpleMaskMoney.setMask('#' + currency.id, options)
  })
}

$.extend(true, $.fn.dataTable.defaults, {
  language: {
    url: 'https://cdn.datatables.net/plug-ins/1.10.22/i18n/Spanish.json',
    buttons: {
      copy: 'Copiar',
      copySuccess: {
        _: 'Copiado %d registros',
        1: 'Copiado 1 registro'
      },
      print: 'Imprimir',
      colvis: 'Editar columnas',
      colvisRestore: 'Restaurar Columnas',
      pageLength: {
        _: 'Mostrar %d registros',
        '-1': 'Todos los registros'
      }
    }
  },
  order: [
    [0, 'desc']
  ],
  responsive: true,
  paging: true,
  dom: 'Bfrtip',
  processing: true,
  serverSide: true,
  lengthMenu: [
    [20, 50, 100, 300],
    [20, 50, 100, 300]
  ],
  buttons: [],
  drawCallback: function (settings) {
    afterPageLoad()
  }
})

window.datatableButtons = [{
  extend: 'excel',
  text: 'Excel',
  exportOptions: {
    modifier: {
      page: 'all',
      search: 'none'
    },
    columns: [0, 1, 2, 3, 4, 5, 6, 7, 8]
  }
},
'print',
'pageLength',
{
  text: 'Actualizar',
  action: function (e, dt, node, config) {
    dt.ajax.reload(null, false)
  }
}
]

function filterColumn (i) {
  $('table.table').DataTable().column(i).search(
    $('#col' + i + '_filter').val()
  ).draw()
}

window.hideModal = function (modal) {
  $(modal).modal('hide')
  modal.querySelector('form') ? modal.querySelector('form').reset() : ''
}

window.showModal = function (modal) {
  $(modal).modal()
}

$('select.column_filter').on('change', function () {
  filterColumn($(this).attr('data-column'))
})

window.resetModalForm = function () {
  $('.modal').on('hidden.bs.modal', function (e) {
    $(this).find('input:not(.no-reset)').not('input[type=hidden]').val('')
    $(this).find('select:not(.no-reset)').val('')
    $(this).find('.message:not(.no-reset)').text('')
    $(this).find('[name=quantity]').val(1)
    $(this).find('[name=price]').val('0,00')
    $(this).find('[name=amount]').val('0,00')

    this.querySelectorAll('.select__slim').forEach(select => {
      if (select.variable) {
        select.variable.set('')
      }
    })
  })
}

$('#confirmPopup').on('hidden.bs.modal', function (e) {
  const el = document.querySelector('#confirmPopup #confirm')
  const elClone = el.cloneNode(true)
  el.parentNode.replaceChild(elClone, el)
})

window.confirmPopup = function (title, body, eFunc) {
  const modal = document.getElementById('confirmPopup')
  modal.querySelector('.modal-title').innerText = title
  modal.querySelector('.modal-body').innerHTML = body

  showModal(modal)
  modal.querySelector('#confirm').removeEventListener('click', function () {
  })
  modal.querySelector('#confirm').addEventListener('click', function () {
    eFunc()
    hideModal(document.getElementById('confirmPopup'))
  })
}

window.serializeForm = function (form) {
  let currencyField = ''
  if (form.querySelector('.currency-input')) {
    currencyField = form.querySelectorAll('.currency-input')
  }

  const obj = {}
  let currencyKey = false
  const formData = new FormData(form)
  for (const key of formData.keys()) {
    currencyKey = false
    if (key.includes('[]')) {
      obj[key.replace('[]', '')] = formData.getAll(key)
    } else {
      if (currencyField) {
        currencyField.forEach(currency => {
          if (currency.name === key) {
            currencyKey = currency
          }
        })

        if (currencyKey) {
          obj[key] = currencyKey.mask.formatToNumber()
        } else {
          obj[key] = formData.get(key)
        }
      } else {
        obj[key] = formData.get(key)
      }
    }
  }

  return obj
}

window.loadDocumentTypes = function () {
  const select = document.querySelectorAll('select[name=document_type_id]')

  axios.get('/api/documentos')
    .then(function (response) {
      let option = '<option value="" disabled selected>Elija una opción</option>'
      response.data.forEach(function (document) {
        option += `<option value="${document.id}">${document.short_name}</option>`
      })

      select.forEach(s => {
        s.innerHTML = option
      })
    })
}

window.loadPaymentTypes = function () {
  const select = document.querySelectorAll('select[name=payment_type_id]')

  axios.get('/api/tipos-de-pagos')
    .then(function (response) {
      let option = '<option value="" disabled selected>Elija una opción</option>'
      response.data.forEach(function (type) {
        option += `<option value="${type.id}">${ucfirst(type.name)}</option>`
      })

      select.forEach(s => {
        s.innerHTML = option
      })
    })
}

window.loadBalanceTypes = function () {
  const select = document.querySelectorAll('select[name=balance_type_id]')
  const balance_types = [{ name: 'Debe', id: 1 }, { name: 'Haber', id: 2 }]

  let option = '<option value="" disabled selected>Elija una opción</option>'
  balance_types.forEach(type => {
    option += `<option value="${type.id}">${ucfirst(type.name)}</option>`
  })

  select.forEach(s => {
    s.innerHTML = option
  })
}

window.loadRoles = function () {
  const select = document.querySelectorAll('select[name^=roles]')

  axios.get('/api/roles')
    .then(function (response) {
      let option = '<option value="" disabled selected>Elija una opción</option>'
      response.data.forEach(function (rol) {
        option += `<option value="${rol.name}">${ucfirst(rol.name)}</option>`
      })

      select.forEach(s => {
        s.innerHTML = option
      })
    })
}

window.loadPermissions = function () {
  const select = document.querySelectorAll('select[name=permissions]')

  axios.get('/api/permisos')
    .then(function (response) {
      let option = '<option value="" disabled selected>Elija una opción</option>'
      response.data.forEach(function (p) {
        option += `<option value="${p.name}">${ucfirst(p.name)}</option>`
      })

      select.forEach(s => {
        s.innerHTML = option
      })
    })
}

window.loadArticles = function () {
  const select = document.querySelectorAll('select[name=article_id]')

  axios.get('/api/articles')
    .then(function (response) {
      let option = '<option value="" disabled selected>Elija un articulo</option>'
      response.data.forEach(function (article) {
        option += `<option value="${article.id}">${ucfirst(article.name)}</option>`
      })

      select.forEach(s => {
        s.innerHTML = option
      })
    })
  let slimArticle
  selectSlim(slimArticle, 'article_id')
}

window.loadExpenditureTypes = function () {
  const select = document.querySelectorAll('select[name=expenditure_type_id]')

  axios.get('/api/gastos-tipos')
    .then(function (response) {
      let option = '<option value="" disabled selected>Elija un tipo de gasto</option>'

      response.data.forEach(function (res) {
        option += `<option value="${res.id}">${ucfirst(res.name)}</option>`
      })

      select.forEach(s => {
        s.innerHTML = option
      })
    })

  let slimArticle
  selectSlim(slimArticle, 'expenditure_type_id')
}

window.loadArticlesForStatistics = function () {
  const select = document.querySelectorAll('select[name=article_id]')

  axios.get('/api/articlesForStatistics')
    .then(function (response) {
      let option = '<option value="" disabled selected>Elija un articulo</option>'
      response.data.forEach(function (article) {
        option += `<option value="${article.id}">${ucfirst(article.name)}</option>`
      })

      select.forEach(s => {
        s.innerHTML = option
      })
    })
  let slimArticle
  selectSlim(slimArticle, 'article_id')
}

window.loadSupplies = function () {
  const select = document.querySelectorAll('select[name=supply_id]')

  axios.get('/api/insumos')
    .then(function (response) {
      let option = '<option value="" disabled selected>Elija un articulo</option>'
      response.data.forEach(function (supply) {
        option += `<option value="${supply.id}">${ucfirst(supply.name)}</option>`
      })

      select.forEach(s => {
        s.innerHTML = option
      })
    })
  let slimSuplly
  selectSlim(slimSuplly, 'supply_id')
}

window.selectSlim = function (variable, select) {
  select = document.querySelectorAll('select[name=' + select + ']')
  select.forEach(s => {
    s.classList.remove('form-control')
    s.classList.add('select__slim')
    s.variable = new SlimSelect({
      select: s,
      searchPlaceholder: 'Buscar',
      searchText: 'No se a encontrado nada',
      searchFocus: false,
      searchHighlight: true
    })
  })
}

window.loadCustomers = function () {
  const select = document.querySelectorAll('select[name=customer_id]')

  axios.get('/api/clientes')
    .then(function (response) {
      let option = '<option value="" disabled selected>Seleccione un cliente</option>'
      response.data.forEach(function (customer) {
        option += `<option value="${customer.id}">${ucfirst(customer.name)}</option>`
      })

      select.forEach(s => {
        s.innerHTML = option
      })
    })

  let slimCustomer
  selectSlim(slimCustomer, 'customer_id')
}

window.loadEmployees = function () {
  const select = document.querySelectorAll('select[name=employee_id]')

  axios.get('/api/empleados')
    .then(function (response) {
      let option = '<option value="" disabled selected>Seleccione un empleado</option>'
      response.data.forEach(function (employee) {
        option += `<option value="${employee.id}">${ucfirst(employee.name)}</option>`
      })

      select.forEach(s => {
        s.innerHTML = option
      })
    })

  let slimEmployees
  selectSlim(slimEmployees, 'employee_id')
}

window.loadSuppliers = function () {
  const select = document.querySelectorAll('select[name=supplier_id]')

  axios.get('/api/proveedores')
    .then(function (response) {
      let option = '<option value="" disabled selected>Seleccione un proveedor</option>'
      response.data.forEach(function (supplier) {
        option += `<option value="${supplier.id}">${ucfirst(supplier.name)}</option>`
      })

      select.forEach(s => {
        s.innerHTML = option
      })
    })
  let slimSupplier
  selectSlim(slimSupplier, 'supplier_id')
}

window.loadIvaCondition = function () {
  const select = document.querySelectorAll('select[name=iva]')
  const iva_conditions = [
    {
      value: 0,
      name: '0%'
    },
    {
      value: 10.5,
      name: '10.5%'
    },
    {
      value: 21,
      name: '21%'
    },
    {
      value: 27,
      name: '27%'
    }
  ]

  let option = '<option value="" disabled selected>Seleccione el IVA</option>'
  iva_conditions.forEach(function (condition) {
    option += `<option value="${condition.value}">${condition.name}</option>`
  })

  select.forEach(s => {
    s.innerHTML = option
  })
}

window.loadTaxConditions = function () {
  const select = document.querySelectorAll('select[name=tax_condition_id]')

  axios.get('/api/tax_conditions').then(function (response) {
    const tax_conditions = Object.entries(response.data)
    let option = '<option value="" disabled selected>Seleccione una condición</option>'
    tax_conditions.forEach(function (condition) {
      option += `<option value="${condition[0]}">${condition[1]}</option>`
    })

    select.forEach(s => {
      s.innerHTML = option
    })
  })
}

window.loadPatientsByCustomers = function () {
  const customerSelect = document.querySelector('select[name=customer_id]')

  customerSelect.addEventListener('change', function () {
    if (!customerSelect.value) {
      document.querySelectorAll('select[name=patient_id]').forEach(s => {
        s.innerHTML = ''
      })
    } else {
      window.newPatientBtn.disabled = false
      document.querySelector('#newPatientBtnModal input[name=customer_id]').value = customerSelect.value
      fillPatientSelect(customerSelect.value)
    }
  })
}

window.fillPatientSelect = function (customer_id, select_last = false) {
  const patientSelect = document.querySelectorAll('select[name=patient_id]')

  patientSelect.forEach(s => {
    s.innerHTML = '<option value="" disabled selected>Seleccione un paciente</option>'
  })

  axios.get('/api/clientes/json/getPatients/' + customer_id)
    .then(function (response) {
      let option = '<option value="" disabled selected>Seleccione un paciente</option>'

      response.data.forEach(function (patient) {
        option += `<option value="${patient.id}" ${select_last ? 'selected' : ''}>${ucfirst(patient.name)}</option>`
      })

      patientSelect.forEach(s => {
        s.innerHTML = option
      })
    })
}

window.ucfirst = (string) => string.charAt(0).toUpperCase() + string.slice(1)
window.formatDate = function (date, datetime = false) {
  if (date) {
    if (datetime) {
      const dateParts = date.split(' ')
      const datePart = dateParts[0].split('-')
      const day = new Date(datePart[0], datePart[1] - 1, datePart[2])

      return date
        ? day.toLocaleDateString('es-AR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          }) + ' ' + dateParts[1]
        : ''
    }

    const dateParts = date.replace(' ', 'T').split('T')[0].split('-')
    const day = new Date(dateParts[0], dateParts[1] - 1, dateParts[2])

    return date ? day.toLocaleDateString('es-AR', { year: 'numeric', month: '2-digit', day: '2-digit' }) : ''
  }

  return ''
}

window.formatDateForInput = function (date) {
  date = new Date(date)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  return `${year}-${`${month}`.padStart(2, '0')}-${`${day}`.padStart(2, '0')}`
}

window.firstOfMonth = function () {
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth()

  return new Date(year, month, 1)
}

window.getToday = function () {
  return new Date()
}

window.formatPrice = function (price) {
  return numeral(price * 1).format('$0,0.00')
}

window.months = {
  1: 'Enero',
  2: 'Febrero',
  3: 'Marzo',
  4: 'Abril',
  5: 'Mayo',
  6: 'Junio',
  7: 'Julio',
  8: 'Agosto',
  9: 'Septiembre',
  10: 'Octubre',
  11: 'Noviembre',
  12: 'Diciembre'
}

window.shorMonths = {
  1: 'Ene',
  2: 'Feb',
  3: 'Mar',
  4: 'Abr',
  5: 'Mayo',
  6: 'Jun',
  7: 'Jul',
  8: 'Ago',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dic'
}

window.formatOrderNumber = function (number) {
  return `#${`${number}`.padStart(6, '0')}`
}

/* delete methods */
window.deleteModel = function (model, id) {
  axios.delete(`/api/${model}/${id}`)
    .then(function (response) {
      refresh()
      if (typeof reloadPage === 'function') {
        reloadPage()
      }
      showNotification(response.data ? response.data.message : 'Borrado exitoso')
    })
    .catch(function (error) {
      showNotification(error.response ? error.response.data.message : 'Ha ocurrido un error inesperado', 'danger')
    })
}

window.deleteOrder = function (id) {
  deleteModel('trabajos', id)
}

window.deleteOrderItem = function (id) {
  deleteModel('trabajos-item', id)
}

window.deletePatient = function (id) {
  deleteModel('pacientes', id)
}

window.deleteImage = function (id) {
  deleteModel('imagen', id)
}

window.deleteCustomer = function (id) {
  deleteModel('clientes', id)
}

window.deleteCustomerPayments = function (id) {
  if (confirm('¿Seguro de borrar el pago #' + id + '?')) {
    deleteModel('pagos', id)
    refresh()
  }
}

window.deleteSupplierPayments = function (id) {
  if (confirm('¿Seguro de borrar el pago #' + id + '?')) {
    deleteModel('pagos-proveedores', id)
    refresh()
  }
}

window.deleteSupplier = function (id) {
  deleteModel('proveedores', id)
}

window.deleteCustomerBalance = function (id) {
  deleteModel('customer_balance', id)
}

window.deleteButtons = function () {
  document.querySelectorAll("button[data-method='delete']").forEach(button => {
    button.addEventListener('click', function () {
      const data = button.dataset
      confirmPopup(
        data.title,
        data.body,
        () => deleteModel(data.model, data.id))
    })
  })
}

window.removeButtons = function () {
  document.querySelectorAll('[data-can]').forEach(function (node) {
    if (!userCan(node.dataset.can)) {
      node.remove()
    }
  })
}

window.showNotification = function (title, type = 'success') {
  document.getElementById('notifications')
    .innerHTML = `<div class="alert alert-${type}" role="alert">
        ${title}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>`

  setTimeout(() => {
    if (document.querySelector('.alert')) {
      document.querySelector('.alert').remove()
    }
  }, 3000)
}

window.closeOrderConfirmation = function (title, body, id) {
  confirmPopup(
    title,
    body,
    () => closeOrder(id)
  )
}

window.openOrderConfirmation = function (title, body, id) {
  confirmPopup(
    title,
    body,
    () => openOrder(id)
  )
}

window.closePurchaseConfirmation = function (title, body, id) {
  confirmPopup(
    title,
    body,
    () => closePurchase(id)
  )
}

window.closeCreditNoteConfirmation = function (title, body, id) {
  confirmPopup(
    title,
    body,
    () => closeCreditNote(id)
  )
}

window.sendPasswordEmailConfirmation = function (title, body, id) {
  confirmPopup(
    title,
    body,
    () => sendPasswordEmail(id)
  )
}
window.sendPasswordEmail = function (id) {
  axios.get('/api/sendPasswordEmail/' + id)
    .then(function (response) {
      showNotification(response.data.message)
    })
    .catch(function (error) {
      if (error.response) {
        showNotification(error.response.data.message, 'danger')
      } else {
        showNotification('Ha ocurrido un error inesperado', 'danger')
      }
    })
}

window.closeOrder = function (id) {
  axios.get('/api/trabajos/' + id + '/close')
    .then(function (response) {
      loadOrder()
      showNotification(response.data.message)
    })
    .catch(function (error) {
      if (error.response) {
        showNotification(error.response.data.message, 'danger')
      } else {
        showNotification('Ha ocurrido un error inesperado', 'danger')
      }
    })
}

window.openOrder = function (id) {
  axios.get('/api/trabajos/' + id + '/open')
    .then(function (response) {
      loadOrder()
      showNotification(response.data.message)
    })
    .catch(function (error) {
      if (error.response) {
        showNotification(error.response.data.message, 'danger')
      } else {
        showNotification('Ha ocurrido un error inesperado', 'danger')
      }
    })
}

window.closePurchase = function (id) {
  axios.get('/api/compras/' + id + '/close')
    .then(function (response) {
      afterPageLoad()
      loadPurchase()
      showNotification(response.data.message)
    })
    .catch(function (error) {
      if (error.response) {
        showNotification(error.response.data.message, 'danger')
      } else {
        showNotification('Ha ocurrido un error inesperado', 'danger')
      }
    })
}

window.closeCreditNote = function (id) {
  axios.get('/api/notas-de-credito/' + id + '/close')
    .then(function (response) {
      afterPageLoad()
      loadCreditNote()
      showNotification(response.data.message)
    })
    .catch(function (error) {
      if (error.response) {
        showNotification(error.response.data.message, 'danger')
      } else {
        showNotification('Ha ocurrido un error inesperado', 'danger')
      }
    })
}

window.userCan = function (permission) {
  return window.Laravel.user.can[permission]
}

window.fillEditModal = function (url, modalName = 'editModal') {
  const modal = document.getElementById(modalName)
  modal.querySelector('p').innerText = ''
  axios.get(url)
    .then(function (response) {
      Object.keys(response.data).forEach(key => {
        if (key === 'roles') {
          response.data[key].forEach(r => {
            if (modal.querySelector('[name^=roles]') && modal.querySelector('[name^=roles]').multiple) {
              Array.prototype.forEach.call(modal.querySelector('[name^=roles]').options, function (option) {
                if (option.value === r.name) { option.selected = true }
              })
            }
          })
        } else {
          const $input = modal.querySelector('[name=' + key + ']')

          if ($input) {
            switch ($input.type) {
              case 'checkbox':
                $input.checked = response.data[key]
                break
              case 'select-one':
                if ($input.variable) {
                  $input.variable.set(response.data[key])
                } else {
                  Array.prototype.forEach.call($input.options, function (option) {
                    if (option.value * 1 === response.data[key]) { option.selected = true }
                  })
                }
                break
              case 'file':
                break
              default:
                $input.value = response.data[key]
                break
            }
          }
        }
      })
      modal.querySelector('form').action = url
      simpleMaskMoney()
    })
  showModal(modal)
}

window.paymentModalListener = function () {
  document.querySelectorAll('select[name=payment_type_id]').forEach(select => {
    select.addEventListener('change', function () {
      checkSelect(select)
    })

    checkSelect(select)
  })

  function checkSelect (select) {
    if (select.value == 1) {
      select.parentElement.parentElement.querySelector('[for=description]').innerHTML = 'Descripción'
      select.parentElement.parentElement.querySelector('[name=description]').required = false
      select.parentElement.parentElement.querySelector('[name=description]').placeholder = ''
    } else if (select.value == 2) {
      select.parentElement.parentElement.querySelector('[for=description]').innerHTML = 'Descripción <span class="required">Requerido</span>'
      select.parentElement.parentElement.querySelector('[name=description]').required = true
      select.parentElement.parentElement.querySelector('[name=description]').placeholder = 'Ej. Nombre del banco'
    } else if (select.value == 3) {
      select.parentElement.parentElement.querySelector('[for=description]').innerHTML = 'Descripción <span class="required">Requerido</span>'
      select.parentElement.parentElement.querySelector('[name=description]').required = true
      select.parentElement.parentElement.querySelector('[name=description]').placeholder = 'Ej. Nombre del banco y Número de cheque'
    }
  }
}

window.createEditModal = function (inputs, title, modalName = 'editModal') {
  document.querySelector('.modals').innerHTML += modalTemplate(modalName, 'PUT')
  const modal = document.getElementById(modalName)
  modal.querySelector(`#${modalName}Label`).innerText = title

  inputs.forEach(input => {
    modal.querySelector('.modal-body .inputs').innerHTML += inputTemplate(input)
  })

  modal.querySelector('.modal-body').innerHTML += `<div class="form-group">
                        <p class="text-danger message"></p>
                    </div>`
}

window.createNewModal = function (inputs, url, title, modalName = 'newModal') {
  document.querySelector('.modals').innerHTML += modalTemplate(modalName)
  const modal = document.getElementById(modalName)
  modal.querySelector('form').action = url
  modal.querySelector(`#${modalName}Label`).innerText = title

  inputs.forEach(input => {
    modal.querySelector('.modal-body .inputs').innerHTML += inputTemplate(input)
  })
  resetModalForm()
}

window.submitModal = function (e) {
  e.preventDefault()

  const modal = e.target.parentElement.parentElement
  const form = modal.querySelector('form')
  const url = form.action
  let data = serializeForm(form)
  let headers = {
    'Content-Type': 'application/json'
  }

  let multipart = false

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'object') {
      multipart = true
    }
  })

  if (multipart) {
    const formData = new FormData(form)
    headers = {
      'Content-Type': 'multipart/form-data'
    }

    data = formData

    // Display the key/value pairs
    for (const pair of data.entries()) {
      if (['amount', 'net_amount', 'price', 'iibb', 'iva', 'other_taxes'].includes(pair[0])) { // } === 'net_amount' || pair[0] === 'amount' || pair[0] === 'price') {
        data.set(pair[0], pair[1].replaceAll('.', '').replaceAll(',', '.'))
      }
    }
  }

  axios.post(url, data, {
    headers: headers
  })
    .then(function (response) {
      if (response.data.printRedirect) {
        showNotification(response.data.message + ', redirigiendo a pantalla de impresión..')
        setTimeout(() => {
          window.open(response.data.printRedirect, '_blank')
        }, 1000)
      }

      hideModal(modal)
      if (typeof refresh === 'function') {
        refresh()
      }

      showNotification(response.data.message)

      if (typeof reloadPage === 'function') {
        reloadPage()
      }

      if (response.data.redirect) {
        showNotification(response.data.message + ', redirigiendo a la pantalla de compra..')
        setTimeout(() => {
          document.location.href = response.data.redirect
        }, 2000)
      }
    })
    .catch(err => {
      const error = extractError(err)

      if (modal.querySelector('.message')) {
        modal.querySelector('.message').innerText = error
      } else {
        showNotification(error, 'danger')
      }
    })
}

window.extractError = function (error) {
  let result, fatalError

  if (error.response) {
    if (error.response.data.error) {
      result = error.response.data.error + '!'
    } else if (error.response.data.errors && error.response.data.errors.password) {
      result = error.response.data.errors.password.map(error => error).join(', ')
    } else if (error.response.data.message) {
      result = error.response.data.message
    } else {
      fatalError = true
      result = 'Internal error!'
    }
  } else {
    fatalError = true
    result = 'Ha ocurrido un error inesperado'
  }

  if (fatalError) {
    axios.post('/api/error-log', {
      message: error.response
    })
  }

  return result
}

window.submitEditModal = function (e) {
  e.preventDefault()
  const modal = e.target.parentElement.parentElement
  const url = this.action
  const data = serializeForm(this)

  axios.put(url, data)
    .then(response => {
      hideModal(modal)
      refresh()
      showNotification(response.data.message)
      if (typeof reloadPage === 'function') {
        reloadPage()
      }
    })
    .catch(err => {
      const error = extractError(err)

      if (modal.querySelector('.message')) {
        modal.querySelector('.message').innerText = error
      } else {
        showNotification(error, 'danger')
      }
    })
}

window.cleanModalForm = function (id) {
  const modal = document.getElementById(id)
  modal.querySelector('form').reset()
  simpleMaskMoney()
}

window.downloadFile = function (path, fileName) {
  axios({
    url: path,
    method: 'GET',
    responseType: 'blob'
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
  })
}

window.popupCenter = function (url, title, w = 750, h = 750) {
  const left = (screen.width / 2) - (w / 2)
  const top = (screen.height / 2) - (h / 2)
  return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left)
}
